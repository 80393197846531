import { SiAdobexd } from "react-icons/si";
import { RiReactjsLine } from "react-icons/ri";
import { FaServer, FaUserTie } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import { FiShield } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";

const data = [
  {
    id: 1,
    icon: <SiAdobexd />,
    title: "UI/UX Design",
    desc: "My designs are modern and intuitive. I use industry-standard rules to make sure your users have fun using your product.",
  },
  {
    id: 2,
    icon: <RiReactjsLine />,
    title: "Frontend Development",
    desc: "Your product will look good and will be accessible on all devices, including mobile phones, tablets, and desktop.",
  },
  {
    id: 3,
    icon: <FaServer />,
    title: "Backend Development",
    desc: "The security of your business/product is taken seriously right from the start of the project. I will make sure your website/app is secure from attacks.",
  },
  {
    id: 4,
    icon: <AiFillAppstore />,
    title: "App Development",
    desc: "Unlike other developers in the market, I will building an app that runs on both IOS and Android devices without any extra cost to you.",
  },
  {
    id: 5,
    icon: <FiShield />,
    title: "Networking & Cybersecurity",
    desc: "I provide networking and cybersecurity solutions to ensure the protection of your business against cyber threats and vulnerabilities (pentesting).",
  },
  {
    id: 6,
    icon: <BiSupport />,
    title: "IT Support Services",
    desc: "As an IT Consultant, I offer comprehensive IT support services for your business. Whether it's troubleshooting technical issues, managing your network infrastructure, or providing data backup and recovery solutions, I am here to help.",
  },
  {
    id: 7,
    icon: <FaUserTie />,
    title: "IT Consultancy",
    desc: "I provide expert IT consultancy services to help your business navigate through complex technological challenges. From strategic planning to implementation, I offer tailored solutions to optimize your IT infrastructure and drive business growth.",
  },
  {
    id: 8,
    icon: <SiAdobexd />,
    title: "House Interior Design",
    desc: "I specialize in creating stunning and functional interior designs for your house. From contemporary to classic styles, my designs cater to your unique taste and preferences. I use industry-standard tools and techniques to ensure your home's interior is visually appealing and user-friendly.",
  },
  {
    id: 9,
    icon: <RiReactjsLine />,
    title: "Wallpaper Installation",
    desc: "Transform your living space with beautiful wallpapers installed by a professional. As an experienced interior designer, I also offer expert wallpaper installation services. Whether you want to add a splash of color or create an accent wall, I ensure precise and flawless installation for a seamless finish.",
  },
  {
    id: 10,
    icon: <FiShield />,
    title: "Implementation Support",
    desc: "I provide implementation support services to assist businesses in effectively utilizing various tools and technologies. For example, I offer training programs for companies on how to use QuickBooks Online efficiently, ensuring they can leverage its features to streamline their financial processes.",
  },
];

export default data;
