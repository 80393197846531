import Avatar1 from "../../assets/avatar1.jpg";


const data = [
  {
    id: 1,
    quote:
      "It's truly amazing how you did exactly what I want with the little information I gave.Highly recommend!",
    avatar: Avatar1,
    name: "Sophia Wasonga",
    profession: "Financial Adviser",
  },

  
];

export default data;
