// data.js
import { FaAward } from 'react-icons/fa';
import { TiBook } from 'react-icons/ti'; // Corrected import
import { BiHappyHeartEyes } from 'react-icons/bi';

const data = [
  { id: 1, icon: <FaAward />, title: 'Experience', desc: '3+ Years Working' },
  { id: 2, icon: <TiBook />, title: 'Projects', desc: '10+ Completed' }, // Corrected icon import
  { id: 3, icon: <BiHappyHeartEyes />, title: 'Clients', desc: '50+ happy clients' }
];

export default data;
