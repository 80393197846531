import React from 'react';
import { HiDownload } from 'react-icons/hi';
import ReactGA from 'react-ga';
import AboutImage from '../../assets/about.jpg';
import Resu from '../../assets/resu.pdf'; // Make sure this import is correct
import data from './data';
import Card from '../../components/Card';
import './about.css';

const About = () => {
  const trackDownload = () => {
    ReactGA.event({
      category: 'Resume',
      action: 'Download',
      label: 'Resume Downloaded',
    });
  };

  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="Portrait of Joel Arthur" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {data.map((item) => (
              <Card key={item.id} className="about__card">
                <span className="about__card-icon">{item.icon}</span>
                <h5>{item.title}</h5>
                <small>{item.desc}</small>
              </Card>
            ))}
          </div>
          <p>
            As a freelancer specializing in JS, building projects that my clients
            love has always been my passion. With over 3 years of experience in
            the web development industry and having served more than 50 satisfied
            clients from around the globe, I am constantly motivated to take on
            new challenges and exceed expectations.
          </p>
          <p>
            I'm a full-stack web developer. My top priority is to get your
            business online the right way, giving you industry-standard design
            and all the functionality you need to operate smoothly online. Get
            in touch today with the details of your project let's get started!
            Check out my resume below!
          </p>
          <a href={Resu} onClick={trackDownload} className="btn primary">
            Download CV <HiDownload />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
