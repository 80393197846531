import Image1 from "../../assets/project1.jpg";
import Image2 from "../../assets/project2.jpg";
import Image3 from "../../assets/project3.jpg";
import Image4 from "../../assets/project4.jpg";
import Image5 from "../../assets/project5.jpg";
import Image6 from "../../assets/project6.jpg";
import Image7 from "../../assets/project7.jpg";
import Image8 from "../../assets/project8.jpg";
import Image9 from "../../assets/project9.jpg";
import Image10 from "../../assets/project10.jpg";
import Image11 from "../../assets/project11.jpg";
import Image12 from "../../assets/project12.jpg";
import Image13 from "../../assets/project13.jpg";

const data = [
  {
    id: 10,
    category: "Current Project",
    image: Image10,
    title: "Current Project (Babysitter Connect)",
    desc: "A platform that connects busy working mothers with qualified and experienced babysitters..",
    demo: "https://momsb.netlify.app/",
    github: "https://github.com/Arthurjoel12",
  },
  
  {
    id: 1,
    category: "portfolio",
    image: Image1,
    title: "Project One (PORTFOLIO)",
    desc: "I developed my personal portfolio website using REACTJS to showcase my skills and experience. As I gained more clients, I realized that the same technology stack could be used to create websites for them as well. Using my familiarity with these technologies, I started building custom websites for each client, tailoring the design and functionality to their specific needs.",
    demo: "https://grayspace.co.ke",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 2,
    category: "ecommerce",
    image: Image2,
    title: "Project Two (ECOMMERCE)",
    desc: "I developed my own ecommerce website using my expertise in web development and ecommerce platforms. Being knowledgeable in technologies such as Reactjs  HTML, CSS, JavaScript, I built the website from scratch. To improve the user experience of the website, I utilized popular ecommerce platforms such as WooCommerce and Magento.  ",
    demo: "https://grayspace-ecommerce.herokuapp.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 3,
    category: "ecommerce",
    image: Image3,
    title: "Project Three (ECOMMERCE)",
    desc: "I developed my own ecommerce website using my expertise in web development and ecommerce platforms. Being knowledgeable in technologies such as REACTJS  HTML, CSS, JavaScript. I built the website from scratch. To improve the user experience of the website, I utilized popular ecommerce platforms such as WooCommerce and Magento. ",
    demo: "https://grayspace-ecommerce.herokuapp.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 4,
    category: "shopping checkout",
    image: Image4,
    title: "Project Four (SHOPPING CHECKOUT)",
    desc: "I built and developed both the backend and frontend of a shopping cart for EDME entrepreneur ltd company. It was quite challenging, but I managed to complete it successfully. Initially, I had to research and gather information about the requirements needed for the project.",
    demo: " https://payasap.netlify.app/ ",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 5,
    category: "grayspace",
    image: Image5,
    title: "Project Five (GRAYSPACE)",
    desc: "I developed my own ecommerce website using my expertise in web development and ecommerce platforms. Being knowledgeable in technologies such as REACTJS HTML, CSS, JavaScript. I built the website from scratch. To improve the user experience of the website, I utilized popular ecommerce platforms such as WooCommerce and Magento.  ",
    demo: "https://grayspace-ecommerce.herokuapp.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 6,
    category: "frontend",
    image: Image6,
    title: "Project Six (FRONTEND)",
    desc: "I developed my own ecommerce website using my expertise in web development and ecommerce platforms. Being knowledgeable in technologies such as REACTJS HTML, CSS, JavaScript. I built the website from scratch. To improve the user experience of the website, I utilized popular ecommerce platforms such as WooCommerce and Magento. ",
    demo: "https://grayspace-ecommerce.herokuapp.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 7,
    category: "shopping checkout",
    image: Image7,
    title: "Project Seven (SHOPPING CHECKOUT)",
    desc: "I built and developed both the backend and frontend of a shopping cart for EDME entrepreneur ltd company. It was quite challenging, but I managed to complete it successfully. Initially, I had to research and gather information about the requirements needed for the project.",
    demo: "  https://payasap.netlify.app/",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 8,
    category: "backend",
    image: Image8,
    title: "Project Eight (BACKEND)",
    desc: "Using tools such react-redux, was a convenient way to update product information. After completing the development process, I deployed my React.js application to a hosting provider heroku. ",
    demo: "https://grayspace-ecommerce.herokuapp.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 9,
    category: "portfolio",
    image: Image9,
    title: "Project Nine (PORTFOLIO)",
    desc: "I developed a client portfolio website using REACTJS to showcase services and experience. As I gained more clients, I realized that the same technology stack could be used to create websites for them as well. Using my familiarity with these technologies, I started building custom websites for each client, tailoring the design and functionality to their specific needs.",
    demo: "https://sophiahealthfinance.com",
    github: "https://github.com/Arthurjoel12",
  },
  {
    id: 11,
    category: "cybersecurity",
    image: Image11,
    title: "Project X (PENTESTING)",
    desc: "I conducted a penetration test on my ecommerce website using Invicti. Invicti's automated scanning identified vulnerabilities like SQL injection and XSS, providing detailed reports for prioritization. It allowed me to simulate real-world attacks, collaborate with my team, and develop effective countermeasures. After implementing fixes, retesting with Invicti confirmed improved security.",
    demo: "",
    github: "",
  },
];

export default data;
